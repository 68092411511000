import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#009c85',
        secondary: '#34b79b',
        accent: '#f8b62c',
        error: '#b74c4c',
      },
      dark: {
        primary: '#009c85',
        secondary: '#34b79b',
        accent: '#f8b62c',
        error: '#b74c4c',
      },
    },
  },
});
