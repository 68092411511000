import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from  './auth';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
      key: 'tetra-ui-monitor'
  })],
  state: {
    // front
    init: false,
    socketConnected: false,
    isTaskStop: false,
    init_mode: false,

    // back
    version: '0.0.0',
    robot_namespace: 'TETRA_NS',
    robot_ip: 'localhost',

    // db //
    // service
    model: 'm',
    map_id: 1,
    language: 'k',
    use_preview: 0,

    // control
    servo_state: 1,
    use_docking: 1,
    docking_front: 1,
    docking_battery: 30,
    docking_battery_max: 80,
    docking_distance: 0.5,
    location_collision_radius: 0.5,
    base_speed: 0.3,
    jog_front_speed: 0.4,
    jog_back_speed: 0.2,
    jog_rotation_speed: 0.3,
    use_conveyor: 0,
    use_lift: 0,
    use_gpio: 0,
    move_trigger_radius: 0.0,
    move_trigger_speed: 0.0,
    cam_topic_name: '/camera1/color/image_raw',
    weight_obstacle: 20.0,
    auto_power_off: 1,
    auto_docking: 1,

    // ui status
    use_robot_ip: 1,
    use_move_result: 1,
    use_emg_signal: 1,
    use_bumper_signal: 1,
    use_servo_signal: 1,
    use_docking_signal: 1,
    use_load_signal: 1,
    use_system_power: 1,
    use_system_time: 1,
    use_speed: 1,
    use_imu_roll: 0,
    use_imu_pitch: 0,
    use_imu_yaw: 0,

    // ui page
    use_init_mode: 0,
    use_init_login: 0,

    // ui menu
    use_menu_location: 1,
    use_menu_task: 1,
    use_menu_repeat: 1,
    use_menu_mapping: 1,
    use_menu_map: 1,
    use_menu_setting: 1,

    // ui button
    only_home_docking: 0,
    use_main_task: 0,
    use_main_plan: 1,
    use_main_location: 1,
    use_main_map_rotation: 0,
    use_head: 1,
    use_head_button_open: 1,
    use_head_button_void: 1,
    use_head_button_servo: 1,
    use_head_button_jog: 1,
    use_head_button_reload: 1,
    use_head_button_estimate: 1,
    use_setting_admin: 1,

    // ui map
    map_option_grid: 0,
    map_option_lidar: 0,
    map_option_radius: 0,
    map_option_scale: 0,
    lidar_topic_name: '/scan',
    lidar_offset_x: 0.15,
    lidar_offset_y: 0.0,
    robot_width: 0.490,
    robot_top_height: 0.200,
    robot_bottom_height: 0.441,
  },
  mutations: {
    setData(state, payload){
      state[payload.name]= payload.value;
    },
    stopTask(state) {
      state.isTaskStop = true;
    },
    reStartTask(state) {
      state.isTaskStop = false;
    }
  },
  getters: {

  },
  actions: {

  },
  modules: {
    users: auth,
  },
});
