import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import store from '../store';
import proxyHandler from '@/plugins/proxy_handler';

Vue.use(Router);

const router = new Router({
  routes,
});

store.state.init= false;
store.state.init_mode= false;

router.beforeEach(async (to, from, next) => {
  if(!store.state.init){
    const res = await proxyHandler.get('/info');
    const options= res.data;
    if(res.status === 200) for(const option_name in options){
      store.state[option_name] = options[option_name];
    }
    store.state.init= true;
  }

  switch (to.path) {
    case '/login': break;
    case '/admin-testing':
    case '/admin-config':
    case '/admin-calibration':
    case '/admin-setting': {
      const user = store.getters['users/user'];
      if (user) break;

      await store.dispatch('users/logOut');
      await router.replace('/login');
      break;
    }
    default: {
      if(store.state.use_init_login === 1){
        const user = store.getters['users/user'];
        if (user) break;

        await store.dispatch('users/logOut');
        await router.replace('/login');
        break;
      }

      if(store.state.use_init_mode === 1){
        if (store.state.init_mode) break;

        store.state.init_mode= true;
        await router.replace('/mode');
        break;
      }
    }
  }

  next();
});

export default router;
