<template>
  <v-app>
    <v-main>
      <default-modal
        v-if="showModal"
        :message="modalState.message"
        :action="modalState.action"
        @close="closeModal"
      />
      <default-modal
        v-if="!socketConnected"
        :message="'로봇과 연결을 시도하고 있습니다.'"
        action="no-action"
      />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import DefaultModal from '@/components/modals/default_modal.vue';
import io from 'socket.io-client';
import EventBus from '@/main.js';

export default {
  name: 'App',
  components: {
    DefaultModal
  },
  data: () => ({
    showModal: false,
    modalState: {
      message: null,
      action: null
    },
    socket: null,
    intervalID: null,
  }),
  created() {
    this.checkGeneral();
  },
  beforeDestroy() {
    this.socket.disconnect();
    EventBus.$off('socketEmit');
  },
  computed: {
    socketConnected(){
      return this.$store.state.socketConnected;
    },
  },
  methods: {
    checkGeneral() {
      const socketHost = process.env.VUE_APP_API_HOST;
      this.socket = io(socketHost);

      this.socket.on("disconnect", ()=> this.setSocketConnected(false));
      this.socket.on("connect", ()=> this.setSocketConnected(true));
      this.socket.on('reconnect', ()=> this.setSocketConnected(true));

      this.socket.on("autoDocking", this.getSocketAutoDocking);
      this.socket.on("needDocking", this.getSocketNeedDocking);
      this.socket.on("option", this.getSocketOption);

      this.socket.on("docking", (data)=> {
        this.showModal= false;
        EventBus.$emit('docking/controller', data);
      });
      this.socket.on("docking:available", (data)=> {
        EventBus.$emit('docking:available/controller', data);
      });
      this.socket.on("job:goto_location_id", (data)=> {
        EventBus.$emit('job:goto_location_id/progress', data);
        EventBus.$emit('job:goto_location_id/map', data);
      });
      this.socket.on("job:update", (data)=> {
        EventBus.$emit('job:update/progress', data);
        EventBus.$emit('job:update/job', data);
      });
      this.socket.on("job:work_finish", (data)=> {
        EventBus.$emit('job:work_finish/progress', data);
        EventBus.$emit('job:work_finish/job', data);
      });
      this.socket.on("job:job_finish", (data)=> EventBus.$emit('job:job_finish/map', data));
      this.socket.on("robot:gpio", (data)=> {
        EventBus.$emit('robot:gpio/map', data);
        EventBus.$emit('robot:gpio/controller', data);
      });
      this.socket.on("robot:speed", (data)=> EventBus.$emit('robot:speed/map', data));
      this.socket.on("robot:time", (data)=> EventBus.$emit('robot:time/map', data));
      this.socket.on("robot:sys_power", (data)=> EventBus.$emit('robot:sys_power/map', data));
      this.socket.on("robot:dockingStatus", (data)=> EventBus.$emit('robot:dockingStatus/map', data));
      this.socket.on("robot:bumper", (data)=> EventBus.$emit('robot:bumper/map', data));
      this.socket.on("robot:roll", (data)=> EventBus.$emit('robot:roll/map', data));
      this.socket.on("robot:pitch", (data)=> EventBus.$emit('robot:pitch/map', data));
      this.socket.on("robot:yaw", (data)=> EventBus.$emit('robot:yaw/map', data));
      this.socket.on("robot:emg", (data)=> EventBus.$emit('robot:emg/map', data));
      this.socket.on("robot:load", (data)=> EventBus.$emit('robot:load/map', data));
      this.socket.on("robot:battery", (data)=> EventBus.$emit('robot:battery/map', data));
      this.socket.on("robot:map", (data)=> EventBus.$emit('robot:map/map', data));
      this.socket.on("robot:result", (data)=> EventBus.$emit('robot:result/map', data));
      this.socket.on("robot:globalPlan", (data)=> EventBus.$emit('robot:globalPlan/map', data));
      this.socket.on("robot:tf", (data)=> EventBus.$emit('robot:tf/map', data));
      this.socket.on("robot:enterArea", (data)=> EventBus.$emit('robot:enterArea/map', data));
      this.socket.on("all_stop", (data)=> {
        EventBus.$emit('all_stop/job', data);
        EventBus.$emit('all_stop/index', data);
      });
      this.socket.on("topic", (data)=> EventBus.$emit('topic/admin', data));
      this.socket.on("lidar", (data)=> EventBus.$emit('lidar/map', data));

      EventBus.$off('socketEmit');
      EventBus.$on('socketEmit', (name, payload) => {
        this.socket.emit(name, payload);
      });
    },
    setSocketConnected(data){
      EventBus.$emit('socketConnected', data);
      this.$store.commit('setData', {
        name: 'socketConnected',
        value: data,
      });
    },
    getSocketAutoDocking(data){
      const name = data.split("/")[1];

      this.modalState.message = `배터리 잔량이 부족하여 ${name}으로 이동합니다`;
      this.showModal = true;
    },
    getSocketNeedDocking(data){
      this.modalState.message = data? `배터리 잔량이 부족합니다`: '배터리 충전이 완료됐습니다.';
      this.showModal = true;
    },
    getSocketOption(data){
      this.$store.commit('setData', {
        name: data.name,
        value: data.value,
      });
    },
    closeModal() {
      this.showModal= false;
    },
  }
};
</script>

<style>
.Vue-Toastification__container > .Vue-Toastification__toast {
  background-color: #00BFB4 !important;
  font-weight: bold;
  width: auto;
}

.Vue-Toastification__container > .Vue-Toastification__toast > .Vue-Toastification__toast-body {
  font-size: 1.5rem !important;
}
</style>
