const routes = [
  {
    path: '/',
    component: () => import('@/views/common/index.vue'),
  },
  {
    path: '/config',
    component: () => import('@/views/common/menu.vue'),
  },
  {
    path: '/mapping',
    component: () => import('@/views/map/mapping.vue'),
  },
  {
    path: '/mapinfo',
    component: () => import('@/views/map/map_list.vue'),
  },
  {
    path: '/mapEdit',
    name: 'MapEdit',
    component: () => import('@/views/map/map_edit.vue'),
    props: true
  },
  {
    path: '/time',
    component: () => import('@/views/task/time.vue'),
  },
  {
    path: '/location-info',
    component: () => import('@/views/location/location_info.vue'),
  },
  {
    path: '/robot-setting',
    component: () => import('@/views/common/setting.vue'),
  },
  {
    path: '/new-location',
    component: () => import('@/views/location/new_locations/new_location.vue'),
    children: [
      {
        path: '/',
        component: () => import('@/views/location/new_locations/config')
      },
      {
        path: 'map',
        component: () => import('@/views/location/new_locations/map')
      },
      {
        path: 'robot',
        component: () => import('@/views/location/new_locations/robot')
      },
      {
        path: 'modify-location',
        name: 'ModifyLocation',
        component: () => import('@/views/location/new_locations/modify_location.vue'),
        props: true
      },
    ]
  },
  {
    path: '/wall',
    component: () => import('@/views/location/wall'),
  },
  {
    path: '/area',
    component: () => import('@/views/location/area'),
  },
  {
    path: '/task',
    component: () => import('@/views/task/task'),
    children: [
      {
        path: '/',
        component: () => import( '@/views/task/job' ),
      },
      {
        path: 'work',
        component: () => import( '@/views/task/work' ),
      },
      {
        path: 'plan',
        name: 'Plan',
        component: () => import( '@/views/task/plan' ),
        props: true
      },
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/common/login.vue'),
  },
  {
    path: '/admin-config',
    component: () => import('@/views/admin/admin_menu.vue'),
  },
  {
    path: '/admin-setting',
    component: () => import('@/views/admin/admin_setting.vue'),
  },
  {
    path: '/admin-testing',
    component: () => import('@/views/admin/admin_testing.vue'),
  },
  {
    path: '/admin-calibration',
    component: () => import('@/views/admin/admin_calibration.vue'),
  },
  {
    path: '/mode',
    component: () => import('@/views/common/mode.vue'),
  },
  {
    path: '/simple',
    component: () => import('@/views/map/simple.vue'),
  },
];

export default routes;
