import axios from '@/plugins/proxy_handler';

export default {
  namespaced: true,
  state: {
    user: null,
    signUpUser: null,
    roles: null
  },
  getters: {
    user: state => state.user,
  },
  mutations: {
    setUser(state, payload) {
      if (payload) {
        state.user = payload;
      } else {
        state.user = null;
        // window.localStorage.clear();
      }
    },
  },
  actions: {
    async logIn({commit}, {id, password}) {
      try {
        let {data} = await axios.post('/auth/token',
          new URLSearchParams({
            username: id,
            password: password,
            grant_type: 'password',
            client_id: 'erebor',
            client_secret: 'erebor',
          }),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          }
        );

        commit('setUser', data);
        return {status: 'success'};

      } catch (error) {
        console.error('login failed ... \n', error.response.data);
        if (error.response.status === 409) {
          let data = error.response.data;
          switch (data.value.code) {
            case 70000:
              return {status: 'fail', message: '아이디 없거나 비밀번호가 일치하지 않습니다.'}
            default:
              return {status: 'fail', message: data.value.message}
          }
        }

        throw new Error(error);
      }
    },
    logOut({commit}) {
      commit('setUser', null);
    },
  },
};
